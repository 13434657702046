<template>
  <div class="logoPreview" id="logo_preview" v-if="showLogo">
    <div
      v-if="
        (landing.branding.logo && page === 'home' && !landing.branding.logo_preview) ||
        (landing.branding.logo &&
          page === 'advanced' &&
          landing.advanced.tab === 2&& !landing.branding.logo_preview) || (landing.branding.logo && page === 'menuStyles' && !landing.branding.logo_preview) && !landing.branding.header_preview
      "
      class="canvas__logo"
      :style="`justify-content:${positionLogo};height:${
        landing.branding.logo_size.height
      }px;margin-bottom:${
        landing.branding.title[landing.current_lang] ? '0' : '20px'
      };position:relative;width: 100%;padding:0 15px;margin-top:${
        landing.branding.header_image || landing.branding.header_preview ? '-50px' : '20px'
      };`"
    >
      <img
        :src="landing.branding.logo"
        alt=""
        :style="`border-radius:${landing.branding.logo_size.radius}px;height:${landing.branding.logo_size.height}px;width:${landing.branding.logo_size.width}px;`"
      />
    </div>
    <div
      v-if="
      !landing.branding.logo_preview&&
        landing.branding.logo &&
        page !== 'home' &&
        $store.state.listing.listingGroup === null &&
        showLogo &&
        landing.advanced.global.active_logo_inside &&
        page !== 'advanced' &&
        page !== 'order' &&
        page !== 'lining' &&
        page !== 'intro' &&
        page !== 'underConstructor' &&
        page !== 'topMenu' &&
        page !== 'lining' &&
        page !== 'order' &&
        page !== 'profile'&& page !== 'menuStyles'
      "
      class="canvas__logo"
      :style="`padding:0 55px;margin-bottom:20px;justify-content:${positionLogo};position:relative;width:100%;margin-top:${
        landing.branding.header_image &&
        landing.advanced.global.active_header_inside || landing.branding.header_preview
          ? '-50px'
          : '20px'
      };`"
    >
      <img
        :src="landing.branding.logo"
        alt=""
        :style="`border-radius:${landing.branding.logo_size.radius}px;height:${landing.branding.logo_size.height}px;width:${landing.branding.logo_size.width}px`"
      />
    </div>
    <div
      class="logo_preview"
      v-if="landing.branding.logo_preview"
      :style="`display:flex;justify-content:${positionLogo};height:${
        landing.branding.logo_size.height
      }px;margin-bottom:${
        landing.branding.title[landing.current_lang] ? '0' : '20px'
      };position:relative;width: 100%;padding:0 55px;margin-top:${
        landing.branding.header_image|| landing.branding.header_preview ? '-50px' : '20px'
      };`"
    >
      <div
        class="logo_preview_img"
        :style="`border-radius:${landing.branding.logo_size.radius}px;height:${landing.branding.logo_size.height}px;width:${landing.branding.logo_size.width}px;overflow:hidden;`"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LogoPreview",
  props: ["page"],
  data() {
    return {};
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState('menu',['selectedMenuItem']),
    showLogo(){
        if(this.page === 'loader'){
            return false
        }
        if(this.page === 'sidebar'){
            return false;
        }
        if(this.page === 'topMenu'){
            return false;
        }
        if(this.page === 'intro'){
            return false;
        }
        if(this.$store.state.events.page === 'detail'){
            return false;
        }
        return true;
    },
    positionLogo() {
      if (this.landing.branding.layout === "center") {
        return "center";
      } else if (this.landing.branding.layout === "left") {
        return "flex-start";
      } else {
        return "flex-end";
      }
    },
  },
};
</script>

<style>
</style>
